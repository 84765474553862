import React from 'react';
import { useParams } from 'react-router-dom';

import SubscriptionCreatePage from '@/pages/service/subscriptions/SubscriptionCreatePage';
import SubscriptionUpdatePage from '@/pages/service/subscriptions/SubscriptionUpdatePage';

function SubscriptionByIdPage() {
	const { id } = useParams<{ id: string }>();

	return !id || id === 'new' ? <SubscriptionCreatePage /> : <SubscriptionUpdatePage />;
}

export default SubscriptionByIdPage;
