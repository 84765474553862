import React from 'react';

import FormGroup from '@/slices/FormGroup';
import BottomControl from '@/slices/BottomControl';
import WrapperPage from '@/pages/WrapperPage';

import Input from '@/components/Input';

import useAPISubscription from '@/hooks/api/useAPISubscription';
import { useForm } from '@/hooks/useForm';

import InputAutoComplete from '@/components/InputAutoComplete';
import Select from '@/components/Select';
import { Controller } from 'react-hook-form';
import useAPINASPools from '@/hooks/api/useAPINASPools';
import { Expose } from 'class-transformer';
import { SubscriptionHistory, SubscriptionPayment } from '@/shared/models/Subscription';

interface NAS {
	id: string;
	name: string;
	router_src_address: string;
}

interface Plan {
	id: string;
	name: string;
}

interface Client {
	id: string;
	name: string;
}

interface NASPool {
	id: string;
	name: string;
	nas: NAS;
}

class SubscriptionCreateForm {
	@Expose()
	due_day: number = 1;

	@Expose()
	client_id: string = '';
	client: Client = {
		id: '',
		name: '',
	};

	@Expose()
	plan_id: string = '';
	plan: Plan = {
		id: '',
		name: '',
	};

	@Expose()
	connectivity_user?: string;

	@Expose()
	connectivity_password?: string;

	@Expose()
	connectivity_ip?: string;

	@Expose()
	pool_id: string = '';

	at_moderation?: string;
	service_subscription_history?: SubscriptionHistory[];
	service_subscription_payment?: SubscriptionPayment[];
	held_at?: string;
	next_due_date?: string;

	pool: NASPool = {
		id: '',
		name: '',
		nas: {
			id: '',
			name: '',
			router_src_address: '',
		},
	};
}

const ENDPOINT = '/service/subscriptions';
const CREATE_URL = '/subscriptions/new';
const BACKWARD_URL = '/subscriptions';
const EMPTY_FORM = new SubscriptionCreateForm();

function SubscriptionCreatePage() {
	const {
		loading: subscriptionLoading,
		error: subscriptionError,
		clearError: subscriptionClearError,
	} = useAPISubscription();

	const {
		handleSave,
		loading: formLoading,
		error: formError,
		clearError,
		form: { setValue, getValues, control, watch },
	} = useForm<SubscriptionCreateForm>({
		endpoint: ENDPOINT,
		createUrl: CREATE_URL,
		defaultValues: new SubscriptionCreateForm(),
		classConstructor: SubscriptionCreateForm,
	});

	const pool = watch('pool');
	const nas = pool?.nas;
	const nasId = nas?.id;
	const { pools } = useAPINASPools(nasId || null);

	const noNAS = !nas || !pools || pools.length === 0;

	function clearErrors() {
		clearError();
		subscriptionClearError();
	}

	return (
		<WrapperPage title="Subscription" error={formError || subscriptionError} onAlertClose={clearErrors}>
			<form onSubmit={(e) => e.preventDefault()} className="flex flex-1 justify-between flex-col">
				<div className="px-3 mb-4">
					<div className="flex flex-col gap-4">
						<FormGroup title="Finance" grow>
							<div className="flex flex-col gap-4">
								<div className="flex gap-2">
									<div className="w-1/3">
										<Controller
											control={control}
											name="plan_id"
											render={({ field }) => (
												<InputAutoComplete<Plan>
													field={{
														property: 'plan',
														label: 'Plan',
													}}
													value={getValues('plan')}
													endpoint={'/service/plan'}
													searchField={'name'}
													parseKey={(row) => row.id}
													parseDisplay={(row) => row.name}
													onSelect={(selected, selectedKey) => {
														setValue('plan', selected);
														field.onChange(selectedKey);
													}}
													onClear={() => {
														setValue('plan', EMPTY_FORM.plan);
														field.onChange('');
													}}
												/>
											)}
										/>
									</div>
									<div className="w-1/3">
										<Controller
											control={control}
											name="client_id"
											render={({ field }) => (
												<InputAutoComplete<Client>
													field={{
														property: 'client',
														label: 'Customer',
													}}
													value={getValues('client')}
													endpoint={'/client'}
													searchField={'name'}
													parseKey={(row) => row.id}
													parseDisplay={(row) => row.name}
													onSelect={(selected, selectedKey) => {
														setValue('client', selected);
														field.onChange(selectedKey);
													}}
													onClear={() => {
														setValue('client', EMPTY_FORM.client);
														field.onChange('');
													}}
												/>
											)}
										/>
									</div>
									<div className="w-1/3">
										<Controller
											control={control}
											name="due_day"
											render={({ field }) => (
												<Input
													{...field}
													type="number"
													label="Due Day"
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('due_day', parseInt(value))}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
						<FormGroup title="Connectivity">
							<div className="flex flex-row gap-2">
								<div className="w-3/12">
									<InputAutoComplete<NAS>
										field={{
											property: 'infra_nas',
											label: 'NAS',
										}}
										value={getValues('pool.nas') || EMPTY_FORM.pool.nas}
										endpoint={'/infra/nas'}
										searchField={'name'}
										parseKey={(row) => row.id}
										parseDisplay={(row) =>
											row?.name ? `${row.name} (${row.router_src_address})` : ''
										}
										onSelect={(selected) => {
											setValue('pool', {
												id: '',
												name: '',
												nas: selected,
											});
										}}
										onClear={() => {
											setValue('pool', EMPTY_FORM.pool);
											setValue('pool_id', '');
										}}
									/>
								</div>
								<div className="w-3/12">
									<Controller
										control={control}
										name="pool_id"
										render={({ field }) => (
											<Select
												{...field}
												options={
													pools?.map((pool) => ({ key: pool.id, label: pool.name })) || []
												}
												value={field.value}
												onChange={(selected) => setValue('pool_id', selected)}
												disabled={noNAS}
												label="NAS/Pool"
												full
											/>
										)}
									/>
								</div>
								<div className="w-2/12">
									<Controller
										control={control}
										name="connectivity_user"
										render={({ field }) => <Input {...field} label="Connectivity User" full />}
									/>
								</div>
								<div className="w-2/12">
									<Controller
										control={control}
										name="connectivity_password"
										render={({ field }) => (
											<Input {...field} type="password" label="Connectivity Password" full />
										)}
									/>
								</div>
								<div className="w-2/12">
									<Controller
										control={control}
										name="connectivity_ip"
										render={({ field }) => <Input {...field} label="Connectivity IP" full />}
									/>
								</div>
							</div>
						</FormGroup>
					</div>
				</div>

				{/* Bottom Control with Custom Actions */}
				<BottomControl
					backwardUrl={BACKWARD_URL}
					createUrl={CREATE_URL}
					onSave={handleSave}
					loading={formLoading || subscriptionLoading}
				/>
			</form>
		</WrapperPage>
	);
}

export default SubscriptionCreatePage;
