import React, { useState } from 'react';

import DataTable, { DataTableAction, DataTableField } from '@/components/DataTable';
import Filters, { Filter } from '@/slices/Filters';

import { Speed, formatMikrotikRateLimit } from '@/shared/models/service/Speed';
import usePaginatedTablePage from '@/hooks/usePaginatedTablePage';
import WrapperPage from '@/pages/WrapperPage';
import Button from '@/components/Button';
import WhiteBox from '@/components/WhiteBox';
import ConfimationBox from '@/slices/ConfirmationBox';
import { useNavigate } from 'react-router-dom';
import { Plus, SquarePen, Trash2 } from 'lucide-react';

const fields: Array<DataTableField<Speed>> = [
	{ title: 'Description', property: 'description' },
	{ title: 'Rate Limit', property: 'up_max_limit', extractor: (row: Speed) => formatMikrotikRateLimit(row) },
];
const endpoint = '/service/speed';
const formUrl = '/service/speeds/new';

export default function SpeedPage() {
	const filters: Array<Filter> = [
		{
			id: 'description',
			label: 'Description:',
			operator: {
				field: 'description',
				op: 'contains',
			},
		},
	];

	const {
		data,
		error,
		loading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleRemove,
		handleClearError,
		handleFilterChange,
	} = usePaginatedTablePage<Speed>({
		endpoint,
		filters,
	});
	const navigate = useNavigate();
	const [removeId, setRemoveId] = useState<string | undefined>();

	const actions: Array<DataTableAction<Speed>> = [
		{
			title: 'Editar',
			icon: SquarePen,
			action: (row) => navigate(formUrl.replace('/new', `/${row.id}`)),
		},
		{
			title: 'Remover',
			icon: Trash2,
			action: async (row) => {
				setRemoveId(row.id);
			},
		},
	];

	function handleNew() {
		formUrl && navigate(formUrl);
	}

	async function handleOkRemove() {
		if (!removeId) return;
		handleRemove(removeId);
		setRemoveId(undefined);
	}

	function handleCancelRemove() {
		setRemoveId(undefined);
	}

	return (
		<WrapperPage title="Speed" error={error} onAlertClose={handleClearError}>
			<div className="mx-4 flex flex-col gap-4">
				<div className="flex items-center justify-between">
					{filters && (
						<Filters filters={filters} filterValues={filterValues} onFilterChange={handleFilterChange} />
					)}
					{formUrl && <Button icon={Plus} style="roundedOutline" onClick={handleNew} />}
				</div>
				<WhiteBox>
					<DataTable
						fields={fields}
						actions={actions}
						rows={data}
						pages={{ page, size: pageSize, total: totalRecords }}
						onPageSize={handleOnPageSize}
						onPage={handleOnPage}
						onRefresh={handleOnRefresh}
						loading={loading}
						sort={sortField ? { field: sortField, order: sortOrder } : undefined}
						onSort={handleOnSort}
					/>
				</WhiteBox>
				{removeId && (
					<ConfimationBox
						title="Action Confirmation"
						message="Are you sure you want to remove this record?"
						onOk={handleOkRemove}
						onCancel={handleCancelRemove}
						inverted
					/>
				)}
			</div>
		</WrapperPage>
	);
}
