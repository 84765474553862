import React, { useEffect, useState } from 'react';

import DataTable, { DataTableField } from '../components/DataTable';
import Filters, { Filter } from '../slices/Filters';

import { Subscription } from '../shared/models/Subscription';
import Input, { applyMask } from '../components/Input';
import { dueDateValueExtractor, getUTCDate, toLocaleLongDateString } from '../shared/utils';
import usePaginatedTablePage from '../hooks/usePaginatedTablePage';
import { PrismaWhereClause } from '../hooks/api/query';
import WrapperPage from './WrapperPage';
import WhiteBox from '../components/WhiteBox';
import Selection from '../slices/Selection';
import Button from '../components/Button';
import useAPISubscription from '../hooks/api/useAPISubscription';
import Overlay from '../components/Overlay';
import MessageBox from '../slices/MessageBox';
import { getCurrentPeriod, isOverdue } from '../shared/models/SubscriptionDueDateUtils';
import { CLOCK_ICON, PAUSE_ICON, PLAY_ICON } from './util/constants';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useAPIISPSettings from '../hooks/api/useAPIISPSettings';
import { ISPSettings } from '../shared/models/ISPSettings';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { BadgeDollarSign, CircleAlert, LoaderCircle, SquarePen } from 'lucide-react';

const DEFAULT_MAX_OVERDUE_DAYS = 0;

interface StatusProps {
	size?: number;
}

const Moderation = ({ size }: StatusProps) => <PLAY_ICON size={size} className="text-info" />;
const Suspended = ({ size }: StatusProps) => <PAUSE_ICON size={size} className="text-warning" />;
const Scheduled = ({ size }: StatusProps) => <CLOCK_ICON size={size} className="text-warning" />;
const Active = ({ size }: StatusProps) => <PLAY_ICON size={size} className="text-success" />;

const getFields = (
	t: TFunction,
	navigate: NavigateFunction,
	settings: ISPSettings | undefined,
	action: (row: Subscription) => void,
	subscriptionLoadingId?: string,
): Array<DataTableField<Subscription>> => [
	{
		title: t('spm.fields.status'),
		property: 'held_at',
		extractor: (row: Subscription) => {
			const subscriptionMaxOverdueDays = settings?.subscription_max_overdue_days || DEFAULT_MAX_OVERDUE_DAYS;
			const overdue =
				row.next_due_date &&
				subscriptionMaxOverdueDays &&
				isOverdue(new Date(row.next_due_date), subscriptionMaxOverdueDays);
			const scheduled = row.next_due_date && row.next_due_date < new Date().toISOString().split('T')[0];
			const suspended = row.held_at;
			const moderation = row.at_moderation;

			if (moderation) return <Moderation />;
			if (suspended) return <Suspended />;
			if (scheduled) return <Scheduled />;
			if (overdue) return <Scheduled />;

			return <Active />;
		},
	},
	{
		title: t('spm.fields.customer'),
		property: 'client.name',
		extractor: (row: Subscription) => `${row.client.name} (${row.connectivity_user})`,
	},
	{ title: t('spm.fields.plan'), property: 'service_plan_name' },
	{
		title: t('spm.fields.due-day'),
		property: 'due_day',
		extractor: (row) => dueDateValueExtractor(t, row.due_day),
	},
	{
		title: t('spm.fields.due-date'),
		property: 'next_due_date',
		extractor: (row) => (row.next_due_date ? toLocaleLongDateString(row.next_due_date) : '-'),
	},
	{
		title: t('spm.fields.price'),
		property: 'service_plan_price',
		extractor: (row: Subscription) => applyMask(row.service_plan_price.toString()),
	},
	{
		title: t('spm.fields.recurrency'),
		property: 'service_plan_recurrency_interval',
		extractor: (row) => t(`models.subscriptions.recurrency.${row.service_plan_recurrency_interval}`),
	},
	{
		title: '',
		property: 'actions',
		extractor: (row: Subscription) => (
			<div className="flex gap-2">
				<Button
					variant={row.next_due_date && row.next_due_date < getUTCDate() ? 'warning' : 'success'}
					size="xs"
					style="roundedOutline"
					icon={subscriptionLoadingId && subscriptionLoadingId === row.id ? LoaderCircle : BadgeDollarSign}
					onClick={() => action(row)}
				/>
				<Button
					icon={SquarePen}
					style="roundedOutline"
					variant="extraLight"
					size="xs"
					onClick={() => navigate(`/subscriptions/${row.id}`)}
				/>
			</div>
		),
	},
];

const subscriptionTabNames = ['moderation', 'past-due', 'scheduled-suspension', 'upcoming'] as const;
type SubscriptionTabName = (typeof subscriptionTabNames)[number];

function getWherePastDueModeration(): PrismaWhereClause {
	return {
		at_moderation: {
			equals: true,
		},
	};
}

function getWherePastDue(
	settings: ISPSettings | undefined,
	referenceDate: Date = new Date(new Date().setUTCHours(0, 0, 0, 0)),
): PrismaWhereClause {
	const daysOffset = settings?.subscription_max_overdue_days || DEFAULT_MAX_OVERDUE_DAYS;
	return {
		next_due_date: {
			lt: getUTCDate(-daysOffset, referenceDate),
		},
		at_moderation: {
			not: true,
		},
	};
}

function getWhereScheduledForSuspension(
	settings: ISPSettings | undefined,
	referenceDate: Date = new Date(new Date().setUTCHours(0, 0, 0, 0)),
): PrismaWhereClause {
	const daysOffset = settings?.subscription_max_overdue_days || DEFAULT_MAX_OVERDUE_DAYS;
	return {
		AND: [
			{
				next_due_date: {
					gte: getUTCDate(-daysOffset, referenceDate),
				},
			},
			{
				next_due_date: {
					lt: getUTCDate(0, referenceDate),
				},
			},
		],
	};
}

function getWhereUpcoming(referenceDate: Date = new Date(new Date().setUTCHours(0, 0, 0, 0))): PrismaWhereClause {
	return {
		next_due_date: {
			gte: getUTCDate(0, referenceDate),
		},
	};
}

export default function SubscriptionPaymentManagementPage() {
	const { t } = useTranslation('common');
	const navigate = useNavigate();
	const [subscriptionPaymentConfirmation, setSubscriptionPaymentConfirmation] = useState<Subscription>();
	const [activeTab, setActiveTab] = useState<SubscriptionTabName>('scheduled-suspension');
	const [where, setWhere] = useState<PrismaWhereClause>();

	const { settings, error: settingsError } = useAPIISPSettings();
	const {
		renew,
		loading: subscriptionLoading,
		error: subscriptionError,
		clearError: subscriptionClearError,
	} = useAPISubscription();

	const filters: Array<Filter> = [
		{
			id: 'customer',
			label: `${t('spm.filters.customer')}:`,
			operator: {
				field: 'client.name',
				op: 'contains',
			},
		},
	];

	const {
		data,
		error: queryError,
		loading: queryLoading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleFilterChange,
		handleClearError: queryClearError,
	} = usePaginatedTablePage<Subscription>({
		endpoint: '/service/subscriptions',
		initialSortField: 'next_due_date',
		filters,
		fixedWhere: where,
		usePagination: true,
		useSorting: true,
		emptyQueryFetch: false,
	});

	function handleButtonAction(subscription: Subscription) {
		setSubscriptionPaymentConfirmation(subscription);
	}

	async function handleOkPayment(period: string) {
		if (!subscriptionPaymentConfirmation) return;
		await renew(subscriptionPaymentConfirmation.id, period);
		setSubscriptionPaymentConfirmation(undefined);
		handleOnRefresh();
	}

	function handleCancelPayment() {
		setSubscriptionPaymentConfirmation(undefined);
	}

	function handleSelectionOnSelect(selectedTab: string) {
		setActiveTab(selectedTab as SubscriptionTabName);
	}

	useEffect(() => {
		if (!settings) return;
		switch (activeTab) {
			case 'moderation':
				setWhere(getWherePastDueModeration());
				break;
			case 'past-due':
				setWhere(getWherePastDue(settings));
				break;
			case 'scheduled-suspension':
				setWhere(getWhereScheduledForSuspension(settings));
				break;
			case 'upcoming':
				setWhere(getWhereUpcoming());
				break;
		}
	}, [settings, activeTab]);

	function handleClearError() {
		subscriptionClearError();
		queryClearError();
	}

	const fields = getFields(
		t,
		navigate,
		settings,
		handleButtonAction,
		subscriptionLoading && subscriptionPaymentConfirmation ? subscriptionPaymentConfirmation.id : undefined,
	);

	const error = subscriptionError || settingsError || queryError;

	return (
		<WrapperPage title={t('spm.title')} error={error} onAlertClose={handleClearError}>
			<div className="mx-4 mb-4 flex flex-col gap-4">
				<div className="flex flex-col gap-4">
					{filters && (
						<Filters filters={filters} filterValues={filterValues} onFilterChange={handleFilterChange} />
					)}
				</div>
				<WhiteBox className="flex flex-col gap-4">
					<div className="flex">
						<Selection
							tabs={[
								{ key: 'moderation', label: t('spm.tabs.moderation') },
								{ key: 'past-due', label: t('spm.tabs.past-due') },
								{
									key: 'scheduled-suspension',
									label: t('spm.tabs.scheduled-suspension'),
								},
								{ key: 'upcoming', label: t('spm.tabs.upcoming') },
							]}
							onSelect={handleSelectionOnSelect}
							active={activeTab}
						/>
					</div>
					<DataTable
						fields={fields}
						rows={data}
						pages={{ page, size: pageSize, total: totalRecords }}
						onPageSize={handleOnPageSize}
						onPage={handleOnPage}
						onRefresh={handleOnRefresh}
						loading={queryLoading}
						sort={sortField ? { field: sortField, order: sortOrder } : undefined}
						onSort={handleOnSort}
					/>
					<div className="flex flex-col gap-[2px] text-xs text-light">
						<span className="flex gap-2">
							<Active size={16} /> {t('spm.legend.active')}
						</span>
						<span className="flex gap-2">
							<Moderation size={16} /> {t('spm.legend.moderation')}
						</span>
						<span className="flex gap-2">
							<Scheduled size={16} /> {t('spm.legend.scheduled-suspension.label')}
							&nbsp;
							{settings?.subscription_max_overdue_days
								? `(${t('spm.legend.scheduled-suspension.message.with-config', { days: settings.subscription_max_overdue_days })})`
								: `(${t('spm.legend.scheduled-suspension.message.no-config')})`}
						</span>
						<span className="flex gap-2">
							<Suspended size={16} /> {t('spm.legend.suspended')}
						</span>
					</div>
				</WhiteBox>
				{subscriptionPaymentConfirmation && (
					<SubscriptionPaymentConfirmation
						subscription={subscriptionPaymentConfirmation}
						onConfirm={handleOkPayment}
						onCancel={handleCancelPayment}
						loading={subscriptionLoading}
					/>
				)}
			</div>
		</WrapperPage>
	);
}

function SubscriptionPaymentConfirmation({
	subscription,
	onConfirm,
	onCancel,
	loading,
}: {
	subscription: Subscription;
	onConfirm: (period: string) => void;
	onCancel: () => void;
	loading: boolean;
}) {
	const [date, setDate] = useState<Date>(new Date());
	const dateValue = date.toISOString().slice(0, 7);
	const period = getCurrentPeriod(subscription.service_plan_recurrency_interval, date);
	return (
		<Overlay>
			<MessageBox title="Payment Confirmation">
				<div className="flex flex-col gap-2">
					<div className="text-xs flex items-center gap-2 text-light-extra">
						<CircleAlert size={18} />
						<span>Select a different period for late payments.</span>
					</div>
					<div className="flex">
						<Input
							label="Period:"
							condensed
							rounded
							type="month"
							value={dateValue}
							onChange={(text) => {
								const [year, month] = text.split('-');
								setDate(new Date(parseInt(year), parseInt(month) - 1));
							}}
							disabled={loading}
						/>
					</div>
				</div>
				<div className="flex gap-2">
					<div className="w-32">
						<Button
							block
							text="Confirm"
							variant="success"
							icon={loading ? LoaderCircle : undefined}
							iconSpin={loading}
							onClick={() => onConfirm(period)}
							disabled={loading}
						/>
					</div>
					<div className="w-32">
						<Button
							block
							text="Close"
							variant="danger"
							style="outline"
							onClick={() => onCancel()}
							disabled={loading}
						/>
					</div>
				</div>
			</MessageBox>
		</Overlay>
	);
}
