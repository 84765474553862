import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { NASPool } from '../../shared/models/infra/NAS';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export default function useAPINASPools(nasId: string | null) {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);
	const [pools, setPools] = useState<NASPool[] | undefined>(undefined);

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	async function fetchPools() {
		if (!nasId || !tenant) {
			setLoading(false);
			setPools(undefined);
			return;
		}

		setPools(undefined);
		setLoading(true);

		try {
			const result = await api.get(`/infra/nas/${nasId}/pools`);
			setError(undefined);
			setPools(result);
			setLoading(false);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	function clear() {
		setPools(undefined);
		setLoading(false);
		setError(undefined);
	}

	useEffect(() => {
		fetchPools();
	}, [nasId]);

	return { pools, loading, fetchPools, error, clear, refresh: fetchPools };
}
