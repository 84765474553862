import React, { useState } from 'react';
import { LoaderCircle, LucideIcon } from 'lucide-react';

import FormGroup from '@/slices/FormGroup';
import BottomControl, { BottomControlCustomAction } from '@/slices/BottomControl';
import WrapperPage from '@/pages/WrapperPage';
import ConfimationBox from '@/slices/ConfirmationBox';
import Alert from '@/slices/Alert';
import Icon from '@/components/Icon';

import {
	SubscriptionStatus,
	getSubscriptionStatus,
	SubscriptionHistory,
	SubscriptionPayment,
	ISubscriptionState,
} from '@/shared/models/Subscription';
import Input, { applyMask } from '@/components/Input';
import { dueDateValueExtractor, timeSince, toLocaleLongDateString, toLocaleShortDateString } from '@/shared/utils';
import { formatPeriod, isOverdue } from '@/shared/models/SubscriptionDueDateUtils';
import { HISTORY_ICON, PAUSE_ICON, PLAY_ICON, RESUME_ICON, STOP_ICON } from '@/pages/util/constants';
import { GRAVITY_VENTRILOQUO_USER } from '@/constants/gravity';

import useAPISubscription from '@/hooks/api/useAPISubscription';
import useAPIISPSettings from '@/hooks/api/useAPIISPSettings';
import { useForm } from '@/hooks/useForm';
import { Expose } from 'class-transformer';
import { formatMikrotikRateLimit } from '@/shared/models/service/Speed';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';

const ENDPOINT = '/service/subscriptions';
const CREATE_URL = '/subscriptions/new';
const BACKWARD_URL = '/subscriptions';

interface NAS {
	id: string;
	name: string;
	router_src_address: string;
}

interface Plan {
	id: string;
	name: string;
}

interface Client {
	id: string;
	name: string;
}

interface NASPool {
	id: string;
	name: string;
	infra_nas: NAS;
}

export interface Speed {
	id: string;
	description?: string;
	up_max_limit: number;
	up_burst: number;
	up_threshold: number;
	up_time_secs: number;
	up_limit_at: number;
	up_priority: number;
	down_max_limit: number;
	down_burst: number;
	down_threshold: number;
	down_time_secs: number;
	down_limit_at: number;
	down_priority: number;
}

interface Currency {
	id: string;
	name: string;
	symbol: string;
}

class SubscriptionUpdateForm {
	due_day: number = 0;
	client_id: string = '';
	client: Client = {
		id: '',
		name: '',
	};
	plan_id: string = '';
	plan: Plan = {
		id: '',
		name: '',
	};

	@Expose()
	connectivity_user?: string;

	@Expose()
	connectivity_password?: string;

	@Expose()
	connectivity_ip?: string;

	currency: Currency = {
		id: '',
		name: '',
		symbol: '',
	};

	connectivity_speed: Speed = {
		id: '',
		description: '',
		up_max_limit: 0,
		up_burst: 0,
		up_threshold: 0,
		up_time_secs: 0,
		up_limit_at: 0,
		up_priority: 0,
		down_max_limit: 0,
		down_burst: 0,
		down_threshold: 0,
		down_time_secs: 0,
		down_limit_at: 0,
		down_priority: 0,
	};

	service_plan_name: string = '';
	service_plan_price: number = 0;
	service_plan_recurrency_interval: string = '';

	at_moderation?: string;
	service_subscription_history?: SubscriptionHistory[];
	service_subscription_payment?: SubscriptionPayment[];
	held_at?: string;
	next_due_date?: string;
	started_at?: string;
	last_successful_payment?: string;
	canceled_at?: string;

	pool_id: string = '';
	infra_nas_pool: NASPool = {
		id: '',
		name: '',
		infra_nas: {
			id: '',
			name: '',
			router_src_address: '',
		},
	};
}

function SubscriptionUpdatePage() {
	const [confirmTerminate, setConfirmTerminate] = useState(false);
	const [confirmActivate, setConfirmActivate] = useState(false);
	const [confirmToggleHold, setConfirmToggleHold] = useState(false);

	const { settings, error: settingsError } = useAPIISPSettings();
	const {
		activate,
		terminate,
		toggleHold,
		loading: subscriptionLoading,
		error: subscriptionError,
		clearError: subscriptionClearError,
	} = useAPISubscription();

	const {
		handleSave,
		loading: formLoading,
		error: formError,
		clearError,
		id,
		form: { getValues, control, reset, watch },
		original,
	} = useForm<SubscriptionUpdateForm>({
		endpoint: ENDPOINT,
		createUrl: CREATE_URL,
		defaultValues: new SubscriptionUpdateForm(),
		classConstructor: SubscriptionUpdateForm,
	});

	const atModeration = watch('at_moderation');
	const serviceSubscriptionPayment = watch('service_subscription_payment');
	const serviceSubscriptionHistory = watch('service_subscription_history');

	const startedAt = watch('started_at');
	const canceledAt = watch('canceled_at');
	const heldAt = watch('held_at');
	const nextDueDate = watch('next_due_date');

	const customActions = original
		? getCustomActions({ started_at: startedAt, canceled_at: canceledAt, held_at: heldAt })
		: [];

	async function handleActivateOk() {
		if (!id) return;
		clearError();
		setConfirmActivate(false);
		const result = await activate(id);
		if (result) reset(result as SubscriptionUpdateForm);
	}

	async function handleTerminateOk() {
		if (!id) return;
		clearError();
		setConfirmTerminate(false);
		const result = await terminate(id);
		if (result) reset(result as SubscriptionUpdateForm);
	}

	async function handleToggleHoldOk() {
		if (!id) return;
		clearError();
		setConfirmToggleHold(false);
		const result = await toggleHold(id);
		if (result) reset(result as SubscriptionUpdateForm);
	}

	function getCustomActions(subscription: ISubscriptionState): BottomControlCustomAction[] {
		const status = getSubscriptionStatus(subscription);
		const loadingIcon: LucideIcon | undefined = subscriptionLoading ? LoaderCircle : undefined;

		const actions: {
			[K in SubscriptionStatus]: BottomControlCustomAction | undefined;
		} = {
			['Pending']: {
				title: 'Activate',
				variant: 'success',
				action: () => setConfirmActivate(true),
				icon: loadingIcon ? LoaderCircle : PLAY_ICON,
				iconSpin: !!loadingIcon,
				disabled: subscriptionLoading,
			},
			['Active']: {
				title: 'Terminate',
				variant: 'danger',
				action: () => setConfirmTerminate(true),
				icon: loadingIcon ? LoaderCircle : STOP_ICON,
				iconSpin: !!loadingIcon,
				disabled: subscriptionLoading,
			},
			['Active-&-Suspended']: {
				title: 'Terminate',
				variant: 'danger',
				action: () => setConfirmTerminate(true),
				icon: loadingIcon ? LoaderCircle : STOP_ICON,
				iconSpin: !!loadingIcon,
				disabled: subscriptionLoading,
			},
			['Terminated']: undefined,
		};

		const holdAction: BottomControlCustomAction | undefined = ['Active', 'Active-&-Suspended'].includes(status)
			? {
					title: subscription.held_at ? 'Resume' : 'Suspend',
					variant: subscription.held_at ? 'success' : 'warning',
					action: () => setConfirmToggleHold(true),
					icon: loadingIcon ? LoaderCircle : subscription.held_at ? RESUME_ICON : PAUSE_ICON,
					disabled: subscriptionLoading,
				}
			: undefined;

		const mainAction = actions[status];
		const actionsArray: BottomControlCustomAction[] = [];

		if (holdAction) actionsArray.push(holdAction);
		if (mainAction) actionsArray.push(mainAction);

		return actionsArray;
	}

	function clearErrors() {
		clearError();
		subscriptionClearError();
	}

	return (
		<WrapperPage
			title="Subscription"
			error={formError || subscriptionError || settingsError}
			onAlertClose={clearErrors}
		>
			<form onSubmit={(e) => e.preventDefault()} className="flex flex-1 gap-4 justify-between flex-col">
				<div className="px-3 mb-4">
					<div className="flex flex-col gap-4">
						{/* Plan Section */}
						<FormGroup title="Plan" grow>
							<div className="flex flex-col gap-4">
								<div className="flex gap-2">
									<div className="w-2/4">
										<Controller
											control={control}
											name="service_plan_name"
											render={({ field }) => <Input {...field} label="Name" disabled full />}
										/>
									</div>
									<div className="w-1/4">
										<Controller
											control={control}
											name="service_plan_price"
											render={({ field }) => (
												<Input
													{...field}
													label="Price"
													disabled
													value={
														field.value
															? `${getValues('currency.symbol')} ${applyMask(getValues('service_plan_price').toString())}`
															: ''
													}
													full
												/>
											)}
										/>
									</div>
									<div className="w-1/4">
										<Controller
											control={control}
											name="service_plan_recurrency_interval"
											render={({ field }) => (
												<Input {...field} label="Recurrency" disabled full />
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>

						{/* Customer Section */}
						<FormGroup title="Customer">
							<div className="flex flex-col gap-4">
								<div className="flex gap-2">
									<div className="w-2/3">
										<Controller
											control={control}
											name="client"
											render={({ field }) => (
												<Input
													{...field}
													label="Name"
													disabled
													full
													value={getValues('client.name')}
												/>
											)}
										/>
									</div>
									<div className="w-1/3">
										<Controller
											control={control}
											name="due_day"
											render={({ field }) => (
												<Input
													{...field}
													label="Due Day"
													disabled
													value={dueDateValueExtractor(t, field.value)}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>

						{/* Connectivity Section */}
						<FormGroup title="Connectivity (PPPoE Client)">
							<div className="flex flex-col gap-4">
								{/* Row 1 */}
								<div className="flex gap-2">
									<div className="w-1/3">
										<Controller
											control={control}
											name="connectivity_user"
											render={({ field }) => (
												<Input
													{...field}
													label="Username"
													full
													value={field.value ? field.value : ''}
												/>
											)}
										/>
									</div>
									<div className="w-1/3">
										<Controller
											control={control}
											name="connectivity_password"
											render={({ field }) => (
												<Input
													{...field}
													type="password"
													label="Password"
													full
													value={field.value ? field.value : ''}
												/>
											)}
										/>
									</div>
									<div className="w-1/3">
										<Controller
											control={control}
											name="connectivity_ip"
											render={({ field }) => (
												<Input
													{...field}
													label="IP"
													full
													value={field.value ? field.value : ''}
												/>
											)}
										/>
									</div>
								</div>

								{/* Row 2 */}
								<div className="flex gap-2">
									<div className="w-1/5">
										<Controller
											control={control}
											name="held_at"
											render={({ field }) => (
												<Input
													{...field}
													label="Suspended At"
													disabled
													value={field.value ? toLocaleLongDateString(field.value) : ''}
													full
												/>
											)}
										/>
									</div>
									<div className="w-2/5">
										<Controller
											control={control}
											name="infra_nas_pool"
											render={({ field }) => {
												const pool = getValues('infra_nas_pool');
												const displayValue = pool
													? pool.infra_nas
														? `${pool.infra_nas.name} (${pool.infra_nas.router_src_address}), Pool: ${pool.name}`
														: 'No NAS found'
													: 'No pool found';
												return (
													<Input
														{...field}
														label="NAS/Pool/IP"
														disabled
														value={displayValue}
														full
													/>
												);
											}}
										/>
									</div>
									<div className="w-2/5">
										<Controller
											control={control}
											name="connectivity_speed"
											render={({ field }) => (
												<Input
													{...field}
													label="Rate Limit"
													disabled
													value={field.value ? formatMikrotikRateLimit(field.value) : ''}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>

						{/* Payments Section */}
						<FormGroup title="Payments">
							<div className="flex flex-col gap-4">
								<div className="flex gap-2">
									<div className="w-1/6">
										<Controller
											control={control}
											name="started_at"
											render={({ field }) => (
												<Input
													{...field}
													label="Activation"
													disabled
													value={field.value ? toLocaleLongDateString(field.value) : ''}
													full
												/>
											)}
										/>
									</div>
									<div className="w-2/6">
										<Controller
											control={control}
											name="last_successful_payment"
											render={({ field }) => (
												<Input
													{...field}
													label="Last Payment"
													disabled
													value={field.value ? toLocaleLongDateString(field.value) : ''}
													full
												/>
											)}
										/>
									</div>
									<div className="w-2/6">
										<Controller
											control={control}
											name="next_due_date"
											render={({ field }) => (
												<Input
													{...field}
													label="Next Payment"
													disabled
													value={field.value ? toLocaleLongDateString(field.value) : ''}
													full
												/>
											)}
										/>
									</div>
									<div className="w-1/6">
										<Controller
											control={control}
											name="canceled_at"
											render={({ field }) => (
												<Input
													{...field}
													label="Cancellation"
													disabled
													value={field.value ? toLocaleLongDateString(field.value) : ''}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>

						{atModeration && (
							<Alert
								type="info"
								title="Under Moderation"
								message="This subscription is under moderation. It will remain so until a payment is made and the next due date is no longer overdue."
							/>
						)}

						{/* Payments */}
						<FormGroup title="Payment History">
							<div className="max-h-96 overflow-y-scroll">
								<ul className="flex flex-col gap-2">
									{(serviceSubscriptionPayment || [])
										.sort((a, b) => (a.payment_date < b.payment_date ? 1 : -1))
										.map((payment) => (
											<li
												key={payment.id}
												className="pb-2 border-b border-gray-200 last:border-none flex items-center gap-2"
											>
												<div className="flex flex-col">
													<p>
														<span className="text-sm">
															{toLocaleShortDateString(payment.payment_date)}
														</span>
														&nbsp;-&nbsp;
														<span className="text-sm font-bold w-32">
															{applyMask(payment.amount.toString())}
														</span>
													</p>
													<p>
														<span className="text-sm">{formatPeriod(payment.period)}</span>
													</p>
												</div>
											</li>
										))}
								</ul>
							</div>
						</FormGroup>

						{/* History */}
						<FormGroup title="Change History">
							<div className="max-h-96 overflow-y-scroll">
								<ul className="flex flex-col gap-2">
									{(serviceSubscriptionHistory || [])
										.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
										.map((history) => (
											<li
												key={history.id}
												className="pb-2 border-b border-gray-200 last:border-none flex items-center gap-2"
											>
												<Icon
													icon={HISTORY_ICON[history.modification_type].type}
													className={HISTORY_ICON[history.modification_type].color}
												/>
												<div className="flex flex-col">
													<p>
														<span className="text-sm">
															{history.user
																? `${history.user.given_name} ${history.user.family_name}`
																: GRAVITY_VENTRILOQUO_USER}
														</span>
														&nbsp;
														<span className="text-sm font-bold w-32">
															{timeSince(history.created_at)}
														</span>
													</p>
													<p>
														<span className="text-sm">{history.message}</span>
													</p>
												</div>
											</li>
										))}
								</ul>
							</div>
						</FormGroup>
					</div>
				</div>

				{/* Bottom Control with Custom Actions */}
				<BottomControl
					backwardUrl={BACKWARD_URL}
					createUrl={CREATE_URL}
					onSave={handleSave}
					loading={formLoading || subscriptionLoading}
					customActions={customActions}
				/>

				{/* Confirmation Boxes */}
				{confirmTerminate && (
					<ConfimationBox
						title="Action Confirmation"
						message="Are you sure you want to terminate this subscription? You may not be allowed to rollback!"
						onOk={handleTerminateOk}
						onCancel={() => setConfirmTerminate(false)}
						inverted
					/>
				)}
				{confirmActivate && (
					<ConfimationBox
						title="Activate Subscription"
						message="Are you sure you want to activate this subscription?"
						onOk={handleActivateOk}
						onCancel={() => setConfirmActivate(false)}
					/>
				)}
				{confirmToggleHold && settings && (
					<ConfimationBox
						title={heldAt ? 'Resume Subscription' : 'Suspend Subscription'}
						message={
							heldAt
								? nextDueDate &&
									settings.subscription_max_overdue_days &&
									isOverdue(new Date(nextDueDate), settings.subscription_max_overdue_days)
									? 'This subscription will resume but will be sent to moderation. Are you sure?'
									: 'Are you sure you want to resume this subscription?'
								: 'Are you sure you want to suspend this subscription?'
						}
						onOk={handleToggleHoldOk}
						onCancel={() => setConfirmToggleHold(false)}
					/>
				)}
			</form>
		</WrapperPage>
	);
}

export default SubscriptionUpdatePage;
