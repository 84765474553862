import React, { useEffect } from 'react';

import { SquareMinus, Plus, X, Undo2 } from 'lucide-react';
import Input from '@/components/Input';
import BottomControl from '@/slices/BottomControl';
import FormGroup from '@/slices/FormGroup';
import { Controller, useFieldArray } from 'react-hook-form';
import WrapperPage from '../WrapperPage';
import { Expose } from 'class-transformer';
import { useForm } from '@/hooks/useForm';
import Button from '@/components/Button';
import NoVPN from './components/NoVPN';
import WithVPN from './components/WithVPN';
import ValidatedVPN from './components/ValidatedVPN';
import { escapeMikrotikPassword, genPass } from '@/shared/utils';

interface NASInputFormPool {
	id?: string;
	name: string;
}

export class NASInputForm {
	@Expose()
	name: string = '';

	@Expose()
	router_src_address: string = '';

	@Expose()
	router_identity: string = '';

	@Expose()
	api_host: string = '';

	@Expose()
	api_port: string = '';

	@Expose()
	api_username?: string = '';

	@Expose()
	api_secret?: string = '';

	@Expose()
	vpn_api_port?: string = '';

	@Expose()
	pppoe_server: boolean = false;

	vpn_ip?: string = '';
	vpn_public_key?: string = '';
	vpn_validated_at?: string = '';

	@Expose()
	infra_nas_pool: NASInputFormPool[] = [
		{
			name: '',
		},
	];
}

const ENDPOINT = '/infra/nas';
const BACKWARD_URL = '/infra/nas';
const CREATE_URL = '/infra/nas/new';
const EMPTY_FORM = new NASInputForm();

function NASByIdPage() {
	const {
		id,
		original,
		handleSave,
		loading,
		error,
		clearError,
		reload,
		loaded,
		form: { control, setValue, getValues, watch },
	} = useForm<NASInputForm>({
		endpoint: ENDPOINT,
		createUrl: CREATE_URL,
		defaultValues: EMPTY_FORM,
		classConstructor: NASInputForm,
		onLoad: handleOnLoad,
	});

	const pools = watch('infra_nas_pool');
	const vpnPublicKey = watch('vpn_public_key');
	const vpnValidatedAt = watch('vpn_validated_at');
	const apiUsername = watch('api_username');
	const apiSecret = watch('api_secret');
	const vpnIp = getValues('vpn_ip');

	useEffect(() => {
		if (pools && pools.length) return;
		setValue('infra_nas_pool', [{ name: '' }]);
	}, [pools]);

	function handleOnLoad(input: NASInputForm) {
		if (!input.api_secret) {
			input.api_secret = '';
		}
		return input;
	}

	function handleNASChanged() {
		reload();
	}

	function handleGenerateUser() {
		const pass = genPass(12, true, true, true);
		setValue('api_username', 'gravityisp');
		setValue('api_secret', pass);
	}

	function handleRevertUser() {
		setValue('api_username', original?.api_username || '');
		setValue('api_secret', original?.api_secret || '');
	}

	function handleEraseUser() {
		setValue('api_username', '');
		setValue('api_secret', '');
	}

	function handleErasePassword() {
		setValue('api_username', original?.api_username || '');
		setValue('api_secret', '');
	}

	return (
		<WrapperPage title="Types" error={error} onAlertClose={clearError}>
			<form onSubmit={(e) => e.preventDefault()} className="flex flex-1 justify-between flex-col">
				<div className="flex flex-col gap-4 mx-4 mb-6">
					<FormGroup
						title="Identification"
						grow
						action={
							<section className="flex text-nowrap">
								<Controller
									control={control}
									name="pppoe_server"
									render={({ field }) => (
										<Input
											{...field}
											label="PPPoE Server"
											disposition="row"
											type="checkbox"
											value={String(getValues('pppoe_server'))}
											onChange={(value) => setValue('pppoe_server', value === 'true')}
										/>
									)}
								/>
							</section>
						}
					>
						<div className="flex flex-col gap-2">
							<div className="flex gap-2">
								<div className="w-1/3">
									<Controller
										control={control}
										name="name"
										rules={{ required: 'Name is required' }}
										render={({ field }) => <Input label="Name" highlight {...field} />}
									/>
								</div>
								<div className="w-1/3">
									<Controller
										control={control}
										name="router_src_address"
										rules={{ required: 'Src. Address is required' }}
										render={({ field }) => (
											<Input label="Router RADIUS Src. Address" highlight {...field} />
										)}
									/>
								</div>
								<div className="w-1/3">
									<Controller
										control={control}
										name="router_identity"
										rules={{ required: 'Identity is required' }}
										render={({ field }) => (
											<Input label="Router RADIUS Identity" highlight {...field} />
										)}
									/>
								</div>
							</div>
						</div>
					</FormGroup>
					<FormGroup title="API" grow>
						<div className="flex flex-col gap-2">
							<div className="flex gap-2">
								<div className="w-5/12">
									<Controller
										control={control}
										name="api_host"
										rules={{ required: 'Host is required' }}
										render={({ field }) => <Input label="Host (Router Public IP)" {...field} />}
									/>
								</div>
								<div className="w-1/12">
									<Controller
										control={control}
										name="api_port"
										render={({ field }) => (
											<Input
												label="Port"
												placeholder="8728"
												{...field}
												value={field.value || ''}
											/>
										)}
									/>
								</div>
								<div className="w-6/12 flex gap-2 relative">
									<div className="w-1/2">
										<Controller
											control={control}
											name="api_username"
											render={({ field }) => (
												<Input
													label="Username"
													{...field}
													contentIcon={
														original &&
														(original.api_username !== apiUsername ||
															getValues('api_secret'))
															? {
																	type: Undo2,
																	color: 'text-info',
																	action: handleRevertUser,
																}
															: {
																	type: X,
																	color: 'text-danger',
																	action: handleEraseUser,
																}
													}
												/>
											)}
										/>
									</div>
									<div className="w-1/2">
										<Controller
											control={control}
											name="api_secret"
											render={({ field }) => (
												<Input
													label="Secret"
													type="password"
													placeholder="Type a new one to change"
													contentIcon={{
														type: X,
														color: 'text-danger',
														action: handleErasePassword,
													}}
													{...field}
													value={field.value || ''}
												/>
											)}
										/>
									</div>
									<div
										className={`absolute flex gap-2 items-center justify-center bg-white bg-opacity-85 z-50 h-full w-full ${!apiUsername ? 'block' : 'hidden'}`}
									>
										<Button
											text="Generate gravityisp API user"
											style="outline"
											onClick={handleGenerateUser}
										/>
									</div>
								</div>
							</div>
							<div className="flex gap-2">
								<div
									className={`flex flex-col gap-2 text-sm items-start w-full ${apiSecret ? 'opacity-100' : 'opacity-25'}`}
								>
									<p>Please copy and paste the code below to set up the API User on your router.</p>
									<textarea
										className={`font-mono text-sm bg-gray-200 resize-none h-24 p-2 rounded-md w-full outline-none ${apiSecret ? 'opacity-100 cursor-pointer' : 'opacity-25 cursor-not-allowed'}`}
										onClick={(event) => (event.target as unknown as HTMLTextAreaElement).select()}
										value={`/user/group/add name=gravity_group policy="read,write,policy,test,reboot,sensitive,api,!winbox,!ssh,!telnet"\n/user/add name="${apiUsername}" password="${escapeMikrotikPassword(apiSecret || 'no-password-generated')}" group="gravity_group"\n\n`}
										readOnly
									/>
								</div>
							</div>
						</div>
					</FormGroup>
					{id && id !== 'new' && loaded && !vpnPublicKey && (
						<NoVPN nasId={id} onNASChanged={handleNASChanged} />
					)}
					{id && id !== 'new' && loaded && vpnPublicKey && !vpnValidatedAt && vpnIp && (
						<WithVPN nasId={id} nasVpnIp={vpnIp} onNASChanged={handleNASChanged} />
					)}
					{id && id !== 'new' && loaded && vpnPublicKey && vpnValidatedAt && (
						<ValidatedVPN
							nasId={id}
							control={control}
							getValues={getValues}
							onNASChanged={handleNASChanged}
						/>
					)}
					<FormGroup title="Pools" grow>
						<Controller
							control={control}
							name="infra_nas_pool"
							render={({ field }) => {
								const { fields, append, remove } = useFieldArray({
									control,
									name: field.name,
								});

								return (
									<div className="flex flex-col gap-2">
										{fields.map((pool, index) => (
											<div key={pool.id} className="flex items-center gap-2 w-full">
												<Controller
													control={control}
													name={`infra_nas_pool.${index}.name`}
													render={({ field }) => <Input full {...field} />}
												/>
												<Button
													type="button"
													onClick={() => remove(index)}
													icon={SquareMinus}
													variant="link"
												/>
											</div>
										))}
										<div className="mt-4">
											<Button
												type="button"
												onClick={() => append({ name: '' })}
												icon={Plus}
												text="Add pool"
											/>
										</div>
									</div>
								);
							}}
						/>
					</FormGroup>
				</div>
				<BottomControl
					backwardUrl={BACKWARD_URL}
					createUrl={CREATE_URL}
					onSave={handleSave}
					loading={loading}
				/>
			</form>
		</WrapperPage>
	);
}

export default NASByIdPage;
