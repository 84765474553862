import React from 'react';
import { Expose } from 'class-transformer';
import FormGroup from '@/slices/FormGroup';
import { Controller } from 'react-hook-form';
import BottomControl from '@/slices/BottomControl';
import WrapperPage from '../WrapperPage';
import Input from '@/components/Input';
import { useForm } from '@/hooks/useForm';
import { Download, Upload } from 'lucide-react';

class SpeedInputForm {
	@Expose()
	description: string = '';

	@Expose()
	upload_max: string = '';

	@Expose()
	download_max: string = '';

	@Expose()
	up_max_limit: number = 0;

	@Expose()
	up_burst: number = 0;

	@Expose()
	up_threshold: number = 0;

	@Expose()
	up_time_secs: number = 0;

	@Expose()
	up_limit_at: number = 0;

	@Expose()
	up_priority: number = 0;

	@Expose()
	down_limit_at: number = 0;

	@Expose()
	down_priority: number = 0;

	@Expose()
	down_time_secs: number = 0;

	@Expose()
	down_threshold: number = 0;

	@Expose()
	down_max_limit: number = 0;

	@Expose()
	down_burst: number = 0;
}

const ENDPOINT = '/service/speed';
const BACKWARD_URL = '/service/speeds';
const CREATE_URL = '/service/speeds/new';
const EMPTY_FORM = new SpeedInputForm();

function SpeedByIdPage() {
	const {
		handleSave,
		loading,
		error,
		clearError,
		form: { control, setValue },
	} = useForm<SpeedInputForm>({
		endpoint: ENDPOINT,
		createUrl: CREATE_URL,
		defaultValues: EMPTY_FORM,
		classConstructor: SpeedInputForm,
	});

	return (
		<WrapperPage title="Speed" error={error} onAlertClose={clearError}>
			<form onSubmit={(e) => e.preventDefault()} className="flex  flex-1 justify-between flex-col ">
				<div className="px-3 mb-4">
					<FormGroup title="Basic Data" grow>
						<div className="flex flex-col">
							<div className="flex h-24">
								<Controller
									control={control}
									name="description"
									render={({ field }) => <Input label="Description" {...field} full />}
								/>
							</div>
						</div>
					</FormGroup>
				</div>
				<div className="flex flex-row flex-wrap px-1 mb-auto ">
					<div className="flex  mb-4 px-2  w-2/6">
						<FormGroup title="Upload/Download (bps)" grow>
							<div className="flex flex-row gap-2 space-x-4">
								<div className=" flex flex-row gap-2">
									<div className="w-11/12">
										<Controller
											control={control}
											name="up_max_limit"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('up_max_limit', parseInt(value))}
													type="number"
													label="Upload Max"
													icon={{ type: Upload, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
									<div className="w-11/12">
										<Controller
											control={control}
											name="down_max_limit"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('down_max_limit', parseInt(value))}
													type="number"
													label="Download Max"
													icon={{ type: Download, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
					</div>
					<div className="flex  mb-4 px-2  w-2/6">
						<FormGroup title="Burst (bps)" grow>
							<div className="flex flex-row gap-2 space-x-4">
								<div className=" flex flex-row  gap-2 ">
									<div className="w-11/12">
										<Controller
											control={control}
											name="up_burst"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('up_burst', parseInt(value))}
													type="number"
													label="Upload"
													icon={{ type: Upload, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
									<div className="w-11/12">
										<Controller
											control={control}
											name="down_burst"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('down_burst', parseInt(value))}
													type="number"
													label="Download"
													icon={{ type: Download, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
					</div>
					<div className="flex  mb-4 px-2  w-2/6">
						<FormGroup title="Threshold (bps)" grow>
							<div className="flex flex-row  gap-2 space-x-4">
								<div className="flex flex-row justify-between gap-3 ">
									<div className="w-11/12">
										<Controller
											control={control}
											name="up_threshold"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('up_threshold', parseInt(value))}
													type="number"
													label="Upload"
													icon={{ type: Upload, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
									<div className="w-11/12">
										<Controller
											control={control}
											name="down_threshold"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('down_threshold', parseInt(value))}
													type="number"
													label="Download"
													icon={{ type: Download, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
					</div>
					<div className="flex  mb-4 px-2  w-2/6">
						<FormGroup title="Time Limit (secs)" grow>
							<div className="flex flex-row  gap-2 space-x-4">
								<div className="flex flex-row justify-between gap-3 ">
									<div className="w-11/12">
										<Controller
											control={control}
											name="up_time_secs"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('up_time_secs', parseInt(value))}
													type="number"
													label="Upload"
													icon={{ type: Upload, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
									<div className="w-11/12">
										<Controller
											control={control}
											name="down_time_secs"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('down_time_secs', parseInt(value))}
													type="number"
													label="Download"
													icon={{ type: Download, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
					</div>
					<div className="flex  mb-4 px-2  w-2/6">
						<FormGroup title="Limit (bps)" grow>
							<div className="flex flex-row  gap-2 space-x-4">
								<div className="flex flex-row justify-between gap-3 ">
									<div className="w-11/12">
										<Controller
											control={control}
											name="up_limit_at"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('up_limit_at', parseInt(value))}
													type="number"
													label="Upload"
													icon={{ type: Upload, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
									<div className="w-11/12">
										<Controller
											control={control}
											name="down_limit_at"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('down_limit_at', parseInt(value))}
													type="number"
													label="Download"
													icon={{ type: Download, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
					</div>
					<div className="flex  mb-4 px-2  w-2/6">
						<FormGroup title="Priority (int)" grow>
							<div className="flex flex-row  gap-2 space-x-4">
								<div className="flex flex-row justify-between gap-3 ">
									<div className="w-11/12">
										<Controller
											control={control}
											name="up_priority"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('up_priority', parseInt(value))}
													type="number"
													label="Upload"
													icon={{ type: Upload, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
									<div className="w-11/12">
										<Controller
											control={control}
											name="down_priority"
											render={({ field }) => (
												<Input
													{...field}
													value={field.value ? String(field.value) : ''}
													onChange={(value) => setValue('down_priority', parseInt(value))}
													type="number"
													label="Download"
													icon={{ type: Download, color: 'text-primary' }}
													full
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</FormGroup>
					</div>
				</div>
				<BottomControl
					backwardUrl={BACKWARD_URL}
					createUrl={CREATE_URL}
					onSave={handleSave}
					loading={loading}
				/>
			</form>
		</WrapperPage>
	);
}

export default SpeedByIdPage;
